import {reset} from 'redux-form';
import * as actionTypes from '../actions/carrier';

const axios = require('axios');

const initialState = {
    carriers: [],
    carrier: null
};

export default function carrier(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SET_CARRIERS:
            return {
                ...state,
                carriers: action.payload
            };
        case actionTypes.SET_CARRIER:
            return {
                ...state,
                carrier: action.payload
            };
        case actionTypes.LOADING:
            return {
                ...state,
                isLoading: action.payload
            };
        case actionTypes.MESSAGE:
            return {
                ...state,
                message: action.payload
            };
        default:
            return state;
    }
};

export const listCarriers = () => (dispatch) => {
    dispatch({ type: actionTypes.MESSAGE, payload: {} });
    axios.get('/carriers/')
      .then(function (response) {
        dispatch({ type: actionTypes.SET_CARRIERS, payload: response.data });
      });
};

export const createCarrier = data => (dispatch) => {
    dispatch({ type: actionTypes.MESSAGE, payload: {} });
    dispatch({ type: actionTypes.LOADING, payload: true });
    
    axios.post('/carriers/', data)
      .then(function (response) {
        dispatch({ type: actionTypes.LOADING, payload: false });
        if(response.status == 201) {
            dispatch({ type: actionTypes.MESSAGE, payload: {status: response.status, type: 'success', text: null, data: response.data} });
        }
      })
      .catch(function (error) {
        dispatch({ type: actionTypes.LOADING, payload: false });

        try {
            dispatch({ type: actionTypes.MESSAGE, payload: {status: null, type: 'danger', text: error.response.data.non_field_errors[0]} });
        } catch(err) {}
        
        try {
            let errors = error.response.data;
            let message = [];
            for(var item in errors) {
                errors[item].forEach((text, key) => {
                    message.push({
                        type: 'danger',
                        text: text
                    })
                })
            }
            dispatch({ type: actionTypes.MESSAGE, payload: message });
        } catch(err) {}
      });
};

export const deleteCarrier = (id, callback) => (dispatch) => {
    dispatch({ type: actionTypes.MESSAGE, payload: {} });
    dispatch({ type: actionTypes.LOADING, payload: true });
    
    axios.delete(`/carriers/${id}/`)
      .then(function (response) {
        dispatch({ type: actionTypes.LOADING, payload: false });
        callback()
      });
};

export const editCarrier = (id, data) => (dispatch) => {
    dispatch({ type: actionTypes.MESSAGE, payload: {} });
    dispatch({ type: actionTypes.LOADING, payload: true });
    axios.put(`/carriers/${id}/`, data)
      .then(function (response) {
        dispatch({ type: actionTypes.LOADING, payload: false });
      })
      .catch(function (error) {
        dispatch({ type: actionTypes.LOADING, payload: false });

        try {
            dispatch({ type: actionTypes.MESSAGE, payload: {type: 'danger', text: error.response.data.non_field_errors[0]} });
        } catch(err) {}
        
        try {
            let errors = error.response.data;
            let message = [];
            for(var item in errors) {
                errors[item].forEach((text, key) => {
                    message.push({
                        type: 'danger',
                        text: text
                    })
                })
            }
            dispatch({ type: actionTypes.MESSAGE, payload: message });
        } catch(err) {}
      });
};

export const setCarrier = (id) => (dispatch) => {
    axios.get(`/carriers/${id}/`)
      .then(function (response) {
        dispatch({ type: actionTypes.SET_CARRIER, payload: response.data });
      });
};

export const resetCarrier = (id) => (dispatch) => {
    dispatch({ type: actionTypes.SET_CARRIER, payload: null });
    dispatch(reset('carrier'));
};